import jQuery from 'jquery/dist/jquery';
import Swal from 'sweetalert2';
import * as bootstrap from 'bootstrap'
import TicketShowServices from "./services";
import Utils from "../../../../utils";
import Calendar from '@event-calendar/core';
import DayGrid from '@event-calendar/day-grid';
import TimeGrid from '@event-calendar/time-grid';
import Interaction from '@event-calendar/interaction';


export default class TicketShowServicesBSM extends TicketShowServices {

    private calendars: any = {}
    createCalendar() {
        //noop
    }

    bindListeners() {
        super.bindListeners();
        (document.querySelector("#ticket_service_calendar_picker") as HTMLButtonElement).addEventListener("change", async (e) => {
            e.preventDefault();
            const calendarIds = Object.keys(this.calendars);
            calendarIds.forEach((calendarId: string) => {
                const calendar = this.calendars[calendarId];
                let oldDate = (document.querySelector("#ticket_service_calendar_picker") as HTMLInputElement).valueAsDate;
                if (oldDate) {
                    calendar.setOption('date', oldDate);
                    const header = document.querySelector("#ticket_service_calendar_date");
                    if (header) {
                        header.innerHTML = `<h3>${oldDate.toLocaleDateString("de-DE", {month: "2-digit", year: "numeric", day: "2-digit"})}</h3>`
                    }
                }
            })
        });
        (document.querySelector("#ticket_service_calendar_arrows_prev") as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const calendarIds = Object.keys(this.calendars);
            calendarIds.forEach((calendarId: string) => {
                const calendar = this.calendars[calendarId];
                let oldDate = calendar.getOption('date');
                oldDate.setDate(oldDate.getDate() - 1)
                calendar.setOption('date', oldDate);
                const header = document.querySelector("#ticket_service_calendar_date");
                if (header) {
                    header.innerHTML = `<h3>${oldDate.toLocaleDateString("de-DE", {month: "2-digit", year: "numeric", day: "2-digit"})}</h3>`
                }
                oldDate.setDate(oldDate.getDate() + 1);
                (document.querySelector("#ticket_service_calendar_picker") as HTMLInputElement).value = oldDate.toISOString().substr(0, 10);
            })
        });
        (document.querySelector("#ticket_service_calendar_arrows_next") as HTMLButtonElement).addEventListener("click", async (e) => {
            e.preventDefault();
            const calendarIds = Object.keys(this.calendars);
            calendarIds.forEach((calendarId: string) => {
                const calendar = this.calendars[calendarId];
                let oldDate = calendar.getOption('date');
                oldDate.setDate(oldDate.getDate() + 1)
                calendar.setOption('date', oldDate);
                const header = document.querySelector("#ticket_service_calendar_date");
                if (header) {
                    header.innerHTML = `<h3>${oldDate.toLocaleDateString("de-DE", {month: "2-digit", year: "numeric", day: "2-digit"})}</h3>`
                }
                oldDate.setDate(oldDate.getDate() + 1);
                (document.querySelector("#ticket_service_calendar_picker") as HTMLInputElement).value = oldDate.toISOString().substr(0, 10);
            })
        });
    }

    createUserCalendar(element: any, userUuid: string, userTitle: string, userColor: string) {
        const calendar = new Calendar({
            target: element,
            props: {
                plugins: [DayGrid, TimeGrid, Interaction],
                options: {
                    view: 'timeGridDay',
                    views: {timeGridDay: {pointer: true}},
                    resources: [{id: 0, title: userTitle, color: userColor, uuid: userUuid}],
                    scrollTime: '09:00:00',
                    allDaySlot: false,
                    events: [],
                    nowIndicator: true,
                    selectable: true,
                    selectBackgroundColor: userColor,
                    firstDay: 1,
                    headerToolbar: {
                        start: "",
                        center: ``,
                        end: ""
                    },
                    eventDrop: async (info: any) => {
                        const e = info.event;
                        const id = e.id;
                        const start = e.start.toUTCString();
                        const end = e.end.toUTCString();
                        await Utils.entity.upsert({
                            uuid: id,
                            from: start,
                            to: end
                        }, "calendar_appointments")

                    },
                    eventClick: (info: any) => {
                        if (this.calendarDropdown) {
                            if (this.calendarDropdownActive) {
                                this.calendarDropdownActive = false;
                                this.calendarDropdownActiveEvent = {}
                                this.calendarDropdown.classList.remove("show");
                            } else {
                                const e = info.jsEvent;
                                e.preventDefault();
                                //@ts-ignore
                                const offset = document.querySelector("#editTicketServiceMap .modal-content").getBoundingClientRect()
                                const bodyOffsets = document.body.getBoundingClientRect();
                                const x = e.pageX - 50;
                                const y = e.pageY - 50 - offset.top;
                                this.calendarDropdown.style.top = `${y}px`;
                                this.calendarDropdown.style.left = `${x}px`;
                                this.calendarDropdown.classList.add("show")
                                this.calendarDropdownActive = true;

                                this.calendarDropdownActiveEvent = info.event;
                            }
                        }
                    },
                    eventResize: async (info: any) => {
                        const e = info.event;
                        const id = e.id;
                        const start = e.start.toUTCString();
                        const end = e.end.toUTCString();
                        await Utils.entity.upsert({
                            uuid: id,
                            from: start,
                            to: end
                        }, "calendar_appointments");
                    },
                    select: async (info: any) => {
                        console.log("AAAAAA");
                        const start = info.start.toUTCString();
                        const end = info.end.toUTCString();
                        const reply = await Utils.entity.upsert({
                            from: start,
                            to: end,
                            user_id: userUuid,
                            ticket_service_map_id: this.calendarCurrentServiceId
                        }, "calendar_appointments")
                        const appointment = reply.data[0]
                        if (calendar) {
                            calendar.unselect()
                            let title = appointment.title
                            if (appointment.external) {
                                title = `[EXTERN]${title}`
                            }
                            calendar.addEvent({id: appointment.uuid, start: appointment.from, end: appointment.to, resourceId: 0, title: title, color: userColor})
                        }
                    }
                }
            }
        });

        const events = this.calendarAppointmentList[userUuid]
        if (events) {
            events.forEach((appointment: any) => {
                if (calendar) {
                    let title = appointment.title
                    if (appointment.external) {
                        title = `[EXTERN]${title}`
                    }
                    calendar.addEvent({id: appointment.id, start: appointment.start, end: appointment.end, resourceId: 0, title: title, color: userColor, editable: appointment.editable, extendedProps: {external: appointment.external}})
                }
            })
        }
        this.calendars[userUuid] = calendar;


        const oldDate = new Date();
        const header = document.querySelector("#ticket_service_calendar_date");
        if (header) {
            header.innerHTML = `<h3>${oldDate.toLocaleDateString("de-DE", {month: "2-digit", year: "numeric", day: "2-digit"})}</h3>`
        }
    }

    onUserClick(e: any) {
        const currentTarget = e.currentTarget;
        const resourceId = currentTarget.getAttribute("data-resource-id") as string;
        const uuid = currentTarget.getAttribute("data-resource-uuid") as string;
        const name = currentTarget.getAttribute("data-resource-name") as string ;
        const color = currentTarget.getAttribute("data-color") as string;
        const parent = document.querySelector("#ticket_service_calendars") as HTMLElement;

        const currentIds = Object.keys(this.calendars)
        if (this.calendars[uuid]) {
            this.calendars[uuid].destroy();
            parent.removeChild((document.querySelector(`#column_${uuid}`) as HTMLElement));
            delete this.calendars[uuid];
            currentTarget.classList.remove("active");
        } else if (currentIds.length <= 5) {
            const parentElem = document.createElement('div');
            parentElem.classList.add("col-2");
            parentElem.id = `column_${uuid}`
            const row = document.createElement('div');
            row.classList.add("row");
            row.innerHTML = `<div class="col-12 text-center"><b>${name}</b></div>`;

            const rowCalendar = document.createElement('div');
            rowCalendar.classList.add("row");
            this.createUserCalendar(rowCalendar, uuid, name, color)

            parentElem.appendChild(row);
            parentElem.appendChild(rowCalendar);

            parent.appendChild(parentElem);
            currentTarget.classList.add("active");
        }
        console.log("this.calendars", this.calendars);
    }
}